import { Col, Divider, InputNumber, message, Row, Upload } from 'antd'
import Title                                               from 'antd/lib/typography/Title'
import { renderFormInput, renderFormList }                 from '../../utils/Element'
import Dao                                                 from '../../utils/Dao'
import BaseForm                                            from '../../components/BaseForm'
import {
  baseUrl, FORMAT_HTML5_DATE,
  FORMAT_SQL,
  MEDIA_UPLOAD_ENDPOINT,
  PATTERN_URL,
  UserAccess
} from '../../utils/Consts'
import { useState, useEffect }                             from 'react'
import { PlusOutlined }                                    from '@ant-design/icons'
import { formatDateTime, hasAccess, parseDateTime }        from '../../utils/Helper'
import moment                                              from 'moment'

export default function BaseCompany({ company, onSave, beforeSave, overrideFormProps, allowedParts }) {

  const sanitizedOverrideFormProps = overrideFormProps ?? {}
  const [photosFileList, setPhotosFileList] = useState([])

  const sanitizeAllowedParts = (parts) => {
    let finalParts = []
    parts.forEach(it => {
      switch (it) {
        case 'gps':
          finalParts.push(it)
          break
        case 'photos':
          if (Dao.loggedUser.isPremium) {
            finalParts.push(it)
          }
          break
        case 'premiumUntil':
          if (hasAccess(Dao.loggedUser, UserAccess.COMPANIES)) {
            finalParts.push(it)
          }
          break
        case 'subscriptions':
          if (hasAccess(Dao.loggedUser, UserAccess.SUBSCRIPTIONS)) {
            finalParts.push(it)
          }
          break
        default:
          break
      }
    })
    return finalParts
  }

  const sanitizedAllowedParts = sanitizeAllowedParts(allowedParts)

  const convertMediaToFileList = (prefix, media) => media.filter(m => m.type.startsWith(prefix)).map(m => ({
    uid: -1 * m.id,
    name: m.name,
    url: m.url,
  }))

  useEffect(() => {
    setPhotosFileList(convertMediaToFileList('image', (company?.media ?? [])))
  }, [company])

  return (
    <BaseForm
      title={!!company?.id ? `Edit wrapper '${company.name}'` : 'Register wrapper'}
      parentSlug={null}
      preparingForm={company == null}
      layout={'vertical'}
      formProps={{
        initialValues: company ? {
          ...company,
          premiumUntil: company.premiumUntil ? formatDateTime(parseDateTime(company.premiumUntil), FORMAT_HTML5_DATE) : '',
        } : {}
      }}
      {...sanitizedOverrideFormProps}
      formItems={[
        <Row gutter={8} key={'general-row'}>
          <Col span={24} key={'general'}>
            <Title level={5}>General</Title>
          </Col>
          {renderFormInput({key: 'name', title: 'Name', colSpan: 24 })}
        </Row>,
        <Divider key={'divider-1'} />,
        <Row gutter={8} key={'address-row'}>
          <Col span={24} key={'address'}>
            <Title level={5}>Address</Title>
          </Col>
          {renderFormInput({key: 'street', title: 'Street', })}
          {renderFormInput({key: 'streetNo', title: 'Street no.', })}
          {renderFormInput({key: 'zipCode', title: 'Zip code', rules: [{ required: true, pattern: /^[0-9a-zA-Z]+$/, message: 'Please enter a valid Zip code.' }] })}
          {renderFormInput({key: 'city', title: 'City', })}
          {renderFormInput({key: 'state', title: 'State', })}
          {renderFormInput({key: 'country', title: 'Country', })}
        </Row>,
        <Divider key={'divider-2'} />,
        <Row gutter={8} key={'person-row'}>
          <Col span={24} key={'person'}>
            <Title level={5}>Contact person</Title>
          </Col>
          {renderFormInput({key: 'firstName', title: 'Name', data: company })}
          {renderFormInput({key: 'lastName', title: 'Surname', data: company })}
          {renderFormInput({key: 'email', title: 'Email', rules: [{ required: true, type: 'email' }] })}
          {renderFormInput({key: 'phone', title: 'Phone', rules: [{ required: true, pattern: /^[0-9+ ()]+$/, message: 'Please enter a valid Phone number.' }] })}
        </Row>,
        sanitizedAllowedParts.indexOf('premiumUntil') > -1 && (
          <div key={'container-premium'}>
            <Divider key={'divider-2-1'}/>
            <Row gutter={8} key={'row-premium'}>
              <Col span={24} key={'premium'}>
                <Title level={5}>Premium</Title>
              </Col>
              {renderFormInput({ key: 'premiumUntil', title: 'Get listed until', rules: [], input: (<input type={'date'} className={'ant-input'} style={{ width: '100%' }}/>) },)}
            </Row>
          </div>),
        <Divider key={'divider-3'} />,
        <Row gutter={8} key={'social-row'}>
          <Col span={24} key={'social'}>
            <Title level={5}>Social</Title>
          </Col>
          {renderFormInput({key: 'webLink', title: 'Website', rules: [{ pattern: PATTERN_URL, message: 'Please enter a valid URL.' }] })}
          {renderFormInput({key: 'fbLink', title: 'Facebook', rules: [] })}
          {renderFormInput({key: 'igLink', title: 'Instagram', rules: [] })}
        </Row>,
        sanitizedAllowedParts.indexOf('gps') > -1 && (
          <div key={'container-gps'}>
            <Divider key={'divider-4'} />
            <Row gutter={8} key={'gps-row'}>
              <Col span={24} key={'gps-location'}>
                <Title level={5}>GPS location</Title>
              </Col>
              {renderFormInput({key: 'lat', title: 'Latitude', colSpan: 12, input: (<InputNumber className={'ant-input-100'} />), rules: [{ required: true, type: 'float', message: 'Please enter a valid latitude.' }] })}
              {renderFormInput({key: 'lng', title: 'Longitude', colSpan: 12, input: (<InputNumber className={'ant-input-100'} />), rules: [{ required: true, type: 'float', message: 'Please enter a valid longitude.' }] })}
            </Row>
          </div>),
        <Divider key={'divider-5'} />,
        <Row gutter={8} key={'skills-row'}>
          { renderFormList({ title: 'Certificates', name: 'certificates', single: 'certificate' }) }
          { renderFormList({ title: 'Services', name: 'services', single: 'service' }) }
        </Row>,
        sanitizedAllowedParts.indexOf('photos') > -1 && (<div key={'gallery-parts'}>
            <Divider key={'divider-6'} />
            <Row gutter={8} key={'gallery'}>
              <Col xs={{ span: 24 }} md={{ span: 12 }} key={'gallery-photos'}>
                <Title level={5}>Photos</Title>
                <Upload
                  action={`${baseUrl()}${MEDIA_UPLOAD_ENDPOINT}`}
                  listType={'picture-card'}
                  fileList={photosFileList}
                  accept={'image/*'}
                  headers={{
                    'X-CarWrapper-AccessToken': Dao.loggedUser.accessToken
                  }}
                  onChange={({ file, fileList }) => setPhotosFileList(fileList)}
                  onRemove={(file) => {
                    let mediaId = !isNaN(file?.response?.id) ? file.response.id : (file.uid * -1)
                    return Dao.media.remove(mediaId).then(() => Promise.resolve(true))
                  }}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </Col>
            </Row>
          </div>),
      ]}
      onSave={async (data) => {
        let beforeSaveResult = true
        if (!!beforeSave) {
          beforeSaveResult = await beforeSave(data)
        }
        if (beforeSaveResult === false) {
          message.error('Can\'t save company')
          return
        }
        if (company?.id) {
          data.id = company.id
        } else {
          // creating
          data.originApp = window.location.pathname.startsWith('/app/') ? 'app' : 'admin'
        }
        if (data.premiumUntil) {
          data.premiumUntil = formatDateTime(moment(data.premiumUntil).endOf('day').utc(), FORMAT_SQL)
        }
        data.mediaIds = photosFileList.map(file => file.response?.id ? file.response?.id : (file.uid * -1))
        try {
          let response = await Dao.company.set(data)
          if (!!onSave) {
            onSave(response)
          }
        } catch (errorResponse) {
          console.log('Can\'t save company', errorResponse.toString())
          message.error('Can\'t save company')
        }
      }}
    >
    </BaseForm>
  )
}