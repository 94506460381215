import { Link }                                     from 'react-router-dom'
import { Button, PageHeader, Skeleton }             from 'antd'
import Dao                                          from '../utils/Dao'
import { useState, useEffect }                      from 'react'
import { formatDateTime, hasAccess, parseDateTime } from '../utils/Helper'
import { UserAccess }                               from '../utils/Consts'
import { SearchableTable }                          from '../utils/Element'

export default function Registrations() {

  const [registrations, setRegistrations] = useState([])
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)

  const userHasWriteAccess = hasAccess(Dao.loggedUser, UserAccess.ADMIN);

  let columns = [
    {
      title: '#', dataIndex: 'id', render: (id, obj, index) => `${index + 1}`,
    },
    {
      title: 'Action', dataIndex: 'id',
      render: (id) => (<>
        <Link to={`/registration/view/${id}`}>View</Link>
        {hasAccess(Dao.loggedUser, UserAccess.ADMIN) && (<Link style={{ marginLeft: '5px' }} to={`/registration/${id}`}>Edit</Link>)}
      </>)
    },
    { title: 'Category', dataIndex: 'categoryName',
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      filters: categories.map(cat => ({ text: cat.name, value: cat.id, })),
      onFilter: (value, record) => record.categoryId === value,
      render: (text, rowData) => rowData.categoryName,
    },
    {
      title: 'Company', dataIndex: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      render: (text, rowData) => hasAccess(Dao.loggedUser, UserAccess.COMPANIES) ? (<Link to={`/company/${rowData.companyId}`}>{rowData.companyName}</Link>) : rowData.companyName,
    },
    {
      title: 'Created', dataIndex: 'created',
      render: (created) => formatDateTime(parseDateTime(created)),
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
  ];

  // remove company col when no admin access
  if (!userHasWriteAccess) {
    columns = columns.filter(col => col.title !== 'Company')
  }

  useEffect(() => {
    Dao.registration.list({
      fields: ['companyName', 'categoryName'],
    }).then(response => {
      setLoading(false)
      setRegistrations(response.data)
    })
  }, [])

  useEffect(() => {
    Dao.category.list().then(response => {
      setCategories(response.data.filter(it => it.isPackage === false))
    })
  }, [])

  // Registrations access && Catalog access
  const newButton = userHasWriteAccess && (<Button type={'primary'}>
    Add new
  </Button>)

  return (
    <>
      <PageHeader title={'Entries'} extra={[
        <Link to={'/registration/new'} key={'add-new'}>
          {newButton}
        </Link>
      ]} />
      {loading && (<Skeleton key={'skeleton'} active />)}
      {!loading && (<SearchableTable
        columns={columns}
        dataSource={registrations} />)
      }
    </>
  )
}