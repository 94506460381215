import Dao                                                                        from '../../utils/Dao'
import BaseForm                                                                   from '../../components/BaseForm'
import { Col, Divider, Input, message, Row, Select, Upload, Form, Space, Button } from 'antd'
import { useState, useEffect }                                                    from 'react'
import Title                                                                      from 'antd/lib/typography/Title'
import { renderFormInput }                                                        from '../../utils/Element'
import { formatDateTime, parseDateTime }                                                from '../../utils/Helper'
import { API_CODES, baseUrl, FORMAT_HTML5_DATETIME, FORMAT_SQL, MEDIA_UPLOAD_ENDPOINT } from '../../utils/Consts'
import { PlusOutlined }                                                                 from '@ant-design/icons'
import { Config }                                                                 from '../../Config'
import moment                                                                     from 'moment'

export default function BaseRegistration({ registration, categories, company, onSave, overrideFormProps }) {

  const [photosFileList, setPhotosFileList] = useState([])
  const [videosFileList, setVideosFileList] = useState([])
  const [currentlyUploadedFileUIds, setCurrentlyUploadedFileUIds] = useState([]);

  const convertMediaToFileList = (prefix, media) => media.filter(m => m.type.startsWith(prefix)).map(m => ({
    uid: -1 * m.id,
    name: m.name,
    url: m.url,
  }))

  useEffect(() => {
    setPhotosFileList(convertMediaToFileList('image', (registration?.media ?? [])))
    setVideosFileList(convertMediaToFileList('video', (registration?.media ?? [])))
  }, [registration])

  const uploadButton = (<div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>)

  const categoryFormElement = categories?.length === 1
    ? (<>
        <Form.Item hidden={true} key={'categoryId'} name={'categoryId'}>
          <Input />
        </Form.Item>
      </>)
    : (renderFormInput({key: 'categoryId', title: 'Category', colSpan: 12, input: (<Select style={{ width: '100%' }}>
      {
        categories.map(cat => (<Select.Option key={`cat-${cat.id}`} value={cat.id}>{cat.name}</Select.Option>))
      }
    </Select>) }))

  let title = (!!registration?.id ? `Edit entry` : `Create entry`) + ' for GCWA 2022'
  if (categories.length === 1) {
    title += ' ' + (categories[0]?.name ?? '') + ` Category`
  }

  const sanitizedOverrideFormProps = overrideFormProps ?? {}
  return (
    <BaseForm
      title={title}
      parentSlug={'/registrations'}
      preparingForm={registration == null}
      layout={'vertical'}
      saveButtonText={'Submit media'}
      buttonSaveDisabled={currentlyUploadedFileUIds?.length > 0}
      buttonSaveExecuting={currentlyUploadedFileUIds?.length > 0}
      formProps={{
        initialValues: registration?.id ? {
          ...registration,
          eventDatetime: formatDateTime(parseDateTime(registration.eventDatetime), FORMAT_HTML5_DATETIME),
        } : {
          categoryId: categories[0]?.id,
          companyId: company?.id,
        }
      }}
      {...sanitizedOverrideFormProps}
      formItems={[
        <Form.Item key={'companyId'} hidden={true} name={'companyId'}>
          <Input />
        </Form.Item>,
        <Row gutter={8} key={'general-row'}>
          <Col span={24} key={'general'}>
            <p style={{marginBottom: '10px'}}>
              BEFORE YOUR WRAP DAY:
              Please provide basic info about your wrap or install, and  date/time and ZOOM link to your Live Wrap Day Session, to allow time for our judges to make time for you.
            </p>
          </Col>
          {renderFormInput({key: 'title', title: 'Title', colSpan: 12 })}
          {categoryFormElement}
          {renderFormInput({key: 'vinylBrand', title: 'Vinyl brand', colSpan: 12 })}
          {renderFormInput({key: 'description', title: 'Description', colSpan: 24, input: (<Input.TextArea />) })}
          {renderFormInput({key: 'eventDatetime', title: 'Event datetime', colSpan: 12, input: (<input type={'datetime-local'} className={'ant-input'} style={{ width: '100%', minHeight: '32px' }} />), rules: [{ required: true, message: 'Please enter a valid datetime.' }] })}
        </Row>,
        <Row gutter={8} key={'social-row'}>
          <Col span={24} key={'social'}>
            <Title level={5}>Social</Title>
            <p style={{marginBottom: '10px'}}>
              ON YOUR WRAP DAY:
              We look forward to enjoy 30 minutes with you on ZOOM to FB Live. Show us your skills, your workshop, let’s chat and exchange ideas. The sessions are always friendly, spontaneous and super fun.
              We will also subsequently share your session on Car Wrapper 3D page.
            </p>
          </Col>
          {renderFormInput({key: 'zoomLink', title: 'Live Wrap ZOOM link', colSpan: 24, rules: [] })}
        </Row>,
        <Space key={'row-action'}>
          <Button type={'primary'} size={'large'} htmlType={'submit'} disabled={currentlyUploadedFileUIds?.length > 0}>
            {(!!registration?.id ? `Update entry` : `Create entry`)}
          </Button>
        </Space>,
        <Divider key={'divider-1'} />,
        <Row gutter={8} key={'gallery'} style={{marginBottom: '10px'}}>
          <Col>
            <p>
              AFTER YOUR WRAP DAY:
              Once your wrap is ready, document it, and submit your final professional wrap movie (format .mp4, max. 3 minutes, HD/ min.
              1280 x 720) and 3 hi-res photos bellow. We will promote your results on social media and web.
            </p>
        </Col>
        </Row>,
        <Row gutter={8} key={'gallery-photos-row'}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} key={'gallery-photos'}>
            <Title level={5}>Media - 3 hi-res photos</Title>
            <Upload
              action={`${baseUrl()}${MEDIA_UPLOAD_ENDPOINT}`}
              listType={'picture-card'}
              fileList={photosFileList}
              accept={'image/*'}
              headers={{
                'X-CarWrapper-AccessToken': Dao.loggedUser.accessToken
              }}
              onChange={({ file, fileList }) => {
                if (file.status === 'removed') {
                  setPhotosFileList(fileList)
                  setCurrentlyUploadedFileUIds(prev => prev.filter(it => it !== file.uid))
                  return
                }
                let currentFile = fileList.filter(f => f.uid === file.uid)[0]
                let isDone = (currentFile.status === 'done' && currentFile.percent === 100) || currentFile.status === 'error'
                if (currentlyUploadedFileUIds.indexOf(currentFile.uid) < 0) {
                  setCurrentlyUploadedFileUIds(prev => [...prev, currentFile.uid])
                } else  if (isDone) {
                  setCurrentlyUploadedFileUIds(prev => prev.filter(it => it !== currentFile.uid))
                }
                setPhotosFileList(fileList)
              }}
              onRemove={(file) => {
                let mediaId = !isNaN(file?.response?.id) ? file.response.id : (file.uid * -1)
                return Dao.media.remove(mediaId).then(() => Promise.resolve(true))
              }}
            >
              {photosFileList.length < Config.maxRegistrationPhotosCount && uploadButton}
            </Upload>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} key={'gallery-videos'}>
            <Title level={5}>Media - final movie (max. 3 minutes)</Title>
            <Upload
              action={`${baseUrl()}${MEDIA_UPLOAD_ENDPOINT}`}
              listType={'picture-card'}
              fileList={videosFileList}
              accept={'video/*'}
              headers={{
                'X-CarWrapper-AccessToken': Dao.loggedUser.accessToken
              }}
              onChange={({ file, fileList }) => {
                if (file.status === 'removed') {
                  setVideosFileList(fileList)
                  setCurrentlyUploadedFileUIds(prev => prev.filter(it => it !== file.uid))
                  return
                }
                let currentFile = fileList.filter(f => f.uid === file.uid)[0]
                let isDone = (currentFile.status === 'done' && currentFile.percent === 100) || currentFile.status === 'error'
                if (currentlyUploadedFileUIds.indexOf(currentFile.uid) < 0) {
                  setCurrentlyUploadedFileUIds(prev => [...prev, currentFile.uid])
                } else  if (isDone) {
                  setCurrentlyUploadedFileUIds(prev => prev.filter(it => it !== currentFile.uid))
                }
                setVideosFileList(fileList)
              }}
              onRemove={(file) => {
                let mediaId = !isNaN(file?.response?.id) ? file.response.id : (file.uid * -1)
                return Dao.media.remove(mediaId).then(() => Promise.resolve(true))
              }}
            >
              {videosFileList.length < Config.maxRegistrationVideosCount && uploadButton}
            </Upload>
          </Col>
        </Row>,
        <Row gutter={8} key={'gallery-info-row'} style={{marginBottom: '10px'}}>
          <Col>
            <p>
              Make sure all your entries are in asap, but latest by November 30, 2022. (the earlier they are in, the longer they are promoted by us)
            </p>
            <p style={{ fontWeight: '700' }}>
              Good luck with being selected to the GCWA finals by our Jury!
            </p>
          </Col>
        </Row>
      ]}
      onSave={async (data) => {
        if (registration?.id) {
          data.id = registration.id
        }
        data.eventDatetime = formatDateTime(moment(data.eventDatetime).utc(), FORMAT_SQL)
        data.mediaIds = photosFileList.concat(videosFileList).map(file => file.response?.id ? file.response?.id : (file.uid * -1))
        data.fields = ['company', 'media']
        try {
          let response = await Dao.registration.set(data)
          if (!!onSave) {
            onSave(response)
          }
        } catch (errorResponse) {
          if (errorResponse.errorCode === API_CODES.ERROR_INVALID_STATE) {
            message.error('Používateľ nemá zakúpený prístup.')
          } else {
            message.error(`Can't save registration: ${errorResponse.error}`)
          }
        }
      }}
    >
    </BaseForm>
  )
}