import * as PropTypes                                           from 'prop-types'
import { useState }                                             from 'react'
import { Button, Form, message, PageHeader, Popconfirm, Space } from 'antd'
import { useHistory }                                           from 'react-router'
import LoadingComponent                                         from './LoadingComponent'

export default function BaseForm( { formItems, title, subtitle, parentSlug, formProps, onSave, preparingForm, onDelete, layout, saveButtonText, headerHidden, buttonSaveDisabled = false, buttonSaveExecuting = false } ) {

  const [executing, setExecuting] = useState(false)
  const history = useHistory()

  const onFinish = async (values: any) => {
    console.log('Success:', values);
    if (isNaN(values['id']) || values['id'] === '') {
      delete values['id']
    }
    setExecuting(true)
    await onSave(values)
    setExecuting(false)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    message.error('Please fill in all required fields.')
  };

  const formAttrs = layout === 'vertical' ? {
    layout: 'vertical',
  } : {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  }
  const buttonSaveAttrs = layout === 'vertical' ? {  } : {
    wrapperCol: { offset: 4, span: 16 }
  }

  const sanitizedSaveButtonText = saveButtonText ?? 'Save'

  const innerElement = preparingForm ? (<LoadingComponent />) : (<Form
    {...formAttrs}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    {...(formProps ? formProps : [])}
  >
    {formItems}

    <Form.Item {...buttonSaveAttrs}>
      <Space>
        <Button type={'primary'} size={'large'} htmlType="submit" loading={executing || buttonSaveExecuting} disabled={buttonSaveDisabled}>
          {sanitizedSaveButtonText}
        </Button>
        {onDelete && (<Popconfirm
            title={'Please confirm delete.'}
            onConfirm={async () => {
                setExecuting(true)
                await onDelete()
                setExecuting(false)
            }}
            onCancel={() => {}}
            okText={'Delete'}
            okType={'danger'}
            cancelText={'Cancel'}
          >
          <Button danger size={'large'} disabled={executing} href={'#'}>Delete</Button>
          </Popconfirm>)

        }
      </Space>
    </Form.Item>
  </Form>)

  return (
    <>
      {(!headerHidden && <PageHeader
        onBack={parentSlug === null ? null : () => parentSlug ? history.push(parentSlug) : history.goBack()}
        title={title}
        subTitle={subtitle}
      />)}
      {innerElement}
    </>
  )
}

BaseForm.propTypes = {
  formItems: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  remove: PropTypes.func,
  subtitle: PropTypes.string,
  parentSlug: PropTypes.string,
  layout: PropTypes.string,
  preparingForm: PropTypes.bool,
}