import { Col, Image, PageHeader, Row, Descriptions, Typography } from 'antd'
import { useHistory, useParams }                                 from 'react-router-dom'
import Dao                                           from '../utils/Dao'
import { useEffect, useState }                       from 'react'

export default function RegistrationDetail() {

  let { id } = useParams()

  const history = useHistory()

  const [registration, setRegistration] = useState(null)

  useEffect(() => {
    async function fetchData() {
      let reg = await Dao.registration.get({ id: id })
      setRegistration(reg)
    }
    fetchData()
  }, [id])

  return (<>
    <PageHeader
      onBack={() => history.goBack()}
      title={`Entry detail (ID: #${registration?.id ?? ''})`} />
    <Descriptions title={'General data'}
                  size={'small'}
                  column={2}
                  labelStyle={{ fontWeight: '600' }}>
      <Descriptions.Item label="Category"><span className="ant-form-text">{registration?.categoryName}</span></Descriptions.Item>
      <Descriptions.Item label="Title"><span className="ant-form-text">{registration?.title}</span></Descriptions.Item>
      <Descriptions.Item label="Vinyl brand"><span className="ant-form-text">{registration?.vinylBrand}</span></Descriptions.Item>
      <Descriptions.Item label="Live Wrap ZOOM link"><span className="ant-form-text">{registration?.zoomLink}</span></Descriptions.Item>
      <Descriptions.Item label="Description" span={2}><span className="ant-form-text">{registration?.description}</span></Descriptions.Item>
    </Descriptions>
    <Typography.Title level={4}>Media</Typography.Title>
    <Row>
      {(registration?.media ?? []).map((media, index) => {
        const inner = media.type.includes('image') ? (<Image
          src={media.url}
        />) : (<video controls style={{ maxWidth: '100%', maxHeight: '400px' }}><source src={media.url} /></video>)
        return (<Col xs={24} md={12} lg={8} key={`col-${index}`}>{inner}</Col>)
      })}
    </Row>
  </>)
}