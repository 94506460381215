import { Form, Input, Button, Row, Col, message, Layout } from 'antd'
import Dao                                                from '../utils/Dao'
import Title                                              from 'antd/lib/typography/Title'
import { useHistory }                                     from 'react-router'
import { API_CODES }                                      from '../utils/Consts'
import React                                              from 'react'
import { Content }                                        from 'antd/lib/layout/layout'

export default function Login() {

  const history = useHistory()

  const onFinish = async (values: any) => {
    console.log('Success:', values)
    try {
      let response = await Dao.user.login(values['email'], values['password'], navigator.userAgent)
      Dao.setLoggedUser(response)
      history.replace(`/`)
    } catch (errorResponse) {
      if (errorResponse?.errorCode === API_CODES.ERROR_INVALID_PARAM) {
        message.error('Invalid email or password')
        return
      }
      message.error('Can\'t login into application')
    }
  };

  return (

    <Layout style={{ height: '100vh' }}>
      <Content>
        <Row justify={'center'} style={{ marginTop: '3em'}}>
          <Col>
            <Title level={2} style={{ textAlign: 'center' }}>CarWrapper</Title>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
            >
              <Form.Item
                key={'email'}
                label={'Email'}
                name={'email'}
                rules={[{ required: true, type: "email", message: 'Please input your username!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key={'password'}
                label={'Password'}
                name={'password'}
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}