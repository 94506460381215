import { Button, PageHeader, Skeleton, Tag, Tooltip } from 'antd'
import { Link }                                       from 'react-router-dom'
import { useState, useEffect }                        from 'react'
import Dao                                            from '../utils/Dao'
import { UserAccess }                                 from '../utils/Consts'
import { hasAccess }                                  from '../utils/Helper'
import { SearchableTable }                            from '../utils/Element'

export default function Users() {
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name',
      render: (text, rowData) => <Link to={`/user/${rowData.id}`}>{text}</Link>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email', dataIndex: 'email', key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Access', dataIndex: 'access', key: 'access',
      render: (_, user) => {
        let tags = []
        for(const property in UserAccess) {
          let isSelected = hasAccess(user, UserAccess[property])
          tags.push(
            <Tooltip key={property} title={property} color={isSelected ? 'green' : 'default'}>
              <Tag key={property} color={isSelected ? 'green' : 'default'}>{isSelected ? '1' : '0'}</Tag>
            </Tooltip>
          )
        }
        return <>{tags}</>
      }
    },
  ];

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    Dao.user.list().then(response => {
      setLoading(false)
      setUsers(response.data)
    })
  }, [])

  return (
    <>
      <PageHeader title={'Users'} extra={[
        <Link key={'add-new'} to={'/user/new'}>
          <Button
            type={'primary'}>
            Add new
          </Button>
        </Link>,
      ]} />
      {loading && (<Skeleton active />)}
      {!loading && (<SearchableTable
        columns={columns}
        dataSource={users} />)
      }
    </>
  )
}