import { Checkbox, Form, Input, message, Row, Col } from 'antd'
import Dao                                          from '../utils/Dao'
import BaseForm                                     from '../components/BaseForm'
import { useHistory, useParams }                    from 'react-router'
import { useEffect, useState }                      from 'react'
import { UserAccess }                               from '../utils/Consts'
import { hasAccess }                                from '../utils/Helper'

export default function User() {

  let { id } = useParams()

  const history = useHistory()
  const [user, setUser] = useState(null)

  useEffect(() => {
    async function fetchData() {
      let user = await Dao.user.get(id)
      setUser(user)
    }
    if (id === 'new') {
      setUser({})
    } else {
      fetchData()
    }
  }, [id])

  const passwordRules = id === 'new' ? [
    { required: true, message: 'Please input your password!', }
  ] : []
  const passwordConfirmRules = id === 'new' ? [
    { required: true, message: 'Please confirm your password!' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
    })
  ] : [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
    })
  ]

  return (
    <BaseForm
      title={!!user?.id ? 'Edit user' : 'Add user'}
      parentSlug={'/users'}
      preparingForm={user == null}
      formProps={{
        initialValues: {
          access: (user?.access ?? []).map(((access, index) => access === 1 ? index : null)).filter(el => el !== null)
        }
      }}
      formItems={[
        <Form.Item
          key={'name'}
          label="Name"
          name="name"
          initialValue={user?.name ?? ''}
          rules={[{ required: true, message: 'Please enter the user name.' }]}
        >
          <Input />
        </Form.Item>,

        <Form.Item
          key={'email'}
          name={'email'}
          label={'E-mail'}
          initialValue={user?.email ?? ''}
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!', },
            { required: true, message: 'Please input your E-mail!', },
          ]}
        >
          <Input />
        </Form.Item>,

        <Form.Item
          key={'password'}
          name={'password'}
          label={'Password'}
          rules={passwordRules}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>,

        <Form.Item
          key={'confirm'}
          name={'confirm'}
          label={'Confirm Password'}
          dependencies={['password']}
          hasFeedback
          rules={passwordConfirmRules}
        >
          <Input.Password />
        </Form.Item>,
        hasAccess(Dao.loggedUser, UserAccess.USERS) && (<Form.Item
          key={'access'}
          name={'access'}
          label={'Access'}>
          <Checkbox.Group>
            <Row>
              {Object.entries(UserAccess).map((access) => (
                <Col span={8} key={access[1]}>
                  <Checkbox value={access[1]}>{access[0]}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>),
      ]}
      onSave={async (data) => {
        if (user?.id) {
          data.id = user.id
        }
        if (hasAccess(Dao.loggedUser, UserAccess.USERS)) {
          data.access = Object.entries(UserAccess).map((access) => (data.access ?? []).indexOf(access[1]) > -1 ? 1 : 0);
        }
        try {
          let response = await Dao.user.set(data)
          setUser(response)
          history.replace(`/user/${response.id}`)
          message.success("User successfully saved")
        } catch (errorResponse) {
          console.log(errorResponse)
          message.error('Can\'t save user')
        }
      }}
      onDelete={!!user?.id ? (async () => {
        try {
          await Dao.user.remove(user.id)
          message.success('User deleted')
          history.replace(`/users`)
        } catch (e) {
          message.error('Deleting user failed')
        }
      }) : null}
    >

    </BaseForm>
  )
}