import Dao                                              from '../utils/Dao'
import { Badge, message, PageHeader, Table, Tabs, Tag } from 'antd'
import { useHistory, useParams }                        from 'react-router'
import { useState, useEffect }                      from 'react'
import BaseCompany                                  from './common/BaseCompany'
import { UserAccess }                               from '../utils/Consts'
import { formatDateTime, hasAccess, parseDateTime } from '../utils/Helper'
import { Link }                                     from 'react-router-dom'
import Text                                         from 'antd/lib/typography/Text'

const { TabPane } = Tabs

export default function Company() {

  let { id } = useParams()

  const history = useHistory()
  const [company, setCompany] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [registrations, setRegistrations] = useState([])

  useEffect(() => {
    async function fetchData() {
      let company = await Dao.company.get(id)
      setCompany(company)

      if (hasAccess(Dao.loggedUser, UserAccess.SUBSCRIPTIONS)) {
        let subsListResponse = await Dao.subscription.list({userId: company.userId})
        setSubscriptions(subsListResponse.data)
      }
      if (hasAccess(Dao.loggedUser, UserAccess.REGISTRATIONS)) {
        let regsListResponse = await Dao.registration.list({companyId: company.id, fields: ['companyName', 'categoryName']})
        setRegistrations(regsListResponse.data)
      }
    }
    if (id === 'new') {
      setCompany({})
    } else {
      fetchData()
    }
  }, [id])

  const subscriptionCols = [
    { title: 'Created', dataIndex: 'created', key: 'name',
      render: (text, rowData) => formatDateTime(parseDateTime(text)),
    },
    {
      title: 'SKU', dataIndex: 'sku', key: 'sku',
      render: (text) => (<Tag>{text}</Tag>)
    },
    {
      title: 'Category', dataIndex: 'category', key: 'categoryId',
      render: (categoryId, rowData) => !categoryId ? '' : hasAccess(Dao.loggedUser, UserAccess.ADMIN) ? (<Link to={`/category/${rowData.categoryId}`}>{rowData.categoryName}</Link>) : rowData.categoryName,
    },
  ]

  let registrationsCols = [
    { title: '#', dataIndex: 'id', render: (id, obj, index) => `${index + 1}` },
    { title: 'Action', dataIndex: 'id', render: (id) => (<>
        <Link to={`/registration/view/${id}`}>View</Link>
        {hasAccess(Dao.loggedUser, UserAccess.ADMIN) && (<Link style={{ marginLeft: '5px' }} to={`/registration/${id}`}>Edit</Link>)}
      </>) },
    { title: 'Category', dataIndex: 'categoryName',
      render: (text, rowData) => hasAccess(Dao.loggedUser, UserAccess.ADMIN) ? (<Link to={`/category/${rowData.categoryId}`}>{rowData.categoryName}</Link>) : rowData.categoryName, },
    { title: 'Company', dataIndex: 'companyName',
      render: (text, rowData) => hasAccess(Dao.loggedUser, UserAccess.COMPANIES) ? (<Link to={`/company/${rowData.companyId}`}>{rowData.companyName}</Link>) : rowData.companyName, },
    { title: 'Created', dataIndex: 'created',
      render: (created) => formatDateTime(parseDateTime(created)) },
  ]

  // remove company col when no admin access
  if (!hasAccess(Dao.loggedUser, UserAccess.ADMIN)) {
    registrationsCols = registrationsCols.filter(col => col.title !== 'Company')
  }

  const contentInTabs = () => {
    let tabs = []
    tabs.push({
      title: 'Edit company',
      badge: null,
      content: (<BaseCompany
        company={company}
        overrideFormProps={{ headerHidden: true }}
        allowedParts={['gps', 'photos', 'premiumUntil', 'subscriptions']}
        onSave={(response) => {
          setCompany(response)
          history.replace(`/company/${response.id}`)
          message.success("Company successfully saved")
        }} />)
    })
    if (hasAccess(Dao.loggedUser, UserAccess.SUBSCRIPTIONS)) {
      tabs.push({
        title: 'Subscriptions',
        badge: subscriptions.length,
        content: (<Table
          key={'table-subscriptions'}
          rowKey={comp => comp.id}
          columns={subscriptionCols}
          dataSource={subscriptions} />)
      })
    }
    if (hasAccess(Dao.loggedUser, UserAccess.REGISTRATIONS)) {
      tabs.push({
        title: 'Registrations',
        badge: registrations.length,
        content: (<Table
          key={'table-registrations'}
          rowKey={reg => reg.id}
          columns={registrationsCols}
          dataSource={registrations} />)
      })
    }
    if (tabs.length === 1) {
      return tabs[0].content
    }
    return (<Tabs defaultActiveKey={'0'}>
      {tabs.map((tab, index) => {
        let badge = tab.badge !== null ? (<Badge showZero={true} count={tab.badge} />) : ''
        return (
          <TabPane tab={(<Text>{tab.title} {badge}</Text>)} key={`${index}`}>{tab.content}</TabPane>
        )
      })}
    </Tabs>)
  }

  return (
    <>
      <PageHeader
        title={`Company '${company?.name}' detail`}
        onBack={() => history.goBack()}/>
      {contentInTabs()}
    </>
  )
}