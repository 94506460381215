import { useEffect, useState }               from 'react'
import Dao                                   from '../utils/Dao'
import BaseForm                              from '../components/BaseForm'
import { Col, Input, message, Row, Select }  from 'antd'
import { useHistory, useParams }             from 'react-router-dom'
import { renderFormInput }                   from '../utils/Element'
import Title                                 from 'antd/lib/typography/Title'
import { FORMAT_HTML5_DATETIME, FORMAT_SQL } from '../utils/Consts'
import { formatDateTime, parseDateTime }     from '../utils/Helper'
import moment                                from 'moment'

export default function Event() {

  let { id } = useParams()

  const history = useHistory()
  const [event, setEvent] = useState(null)

  const states = [
    { value: 'draft', text: 'Draft' },
    { value: 'published', text: 'Published' }
  ]

  useEffect(() => {
    async function fetchData() {
      let ev = await Dao.event.get(id)
      setEvent(ev)
    }
    if (id === 'new') {
      setEvent({})
    } else {
      fetchData()
    }
  }, [id])

  return (
    <BaseForm
      title={!!event?.id ? 'Edit event' : 'Add event'}
      parentSlug={'/events'}
      preparingForm={event == null}
      formProps={{
        initialValues: !!event?.id ? {
          ...event,
          eventDatetime: event.eventDatetime ? formatDateTime(parseDateTime(event.eventDatetime), FORMAT_HTML5_DATETIME) : '',
        } : {
          state: 'draft'
        }
      }}
      formItems={[
        <Row gutter={8} key={'general-row'}>
          <Col span={24} key={'general'}>
            <Title level={5}>General</Title>
          </Col>
          {renderFormInput({key: 'title', title: 'Title', colSpan: 24 })}
          {renderFormInput({key: 'description', title: 'Description', colSpan: 24, input: (<Input.TextArea />) })}
        </Row>,
        <Row gutter={8}  key={'state-row'}>
          {renderFormInput({key: 'state', title: 'State', colSpan: 24, input: (<Select style={{ width: '100%' }}>
                {
                  states.map(cat => (<Select.Option key={`option-${cat.value}`} value={cat.value}>{cat.text}</Select.Option>))
                }
              </Select>) })}
          {renderFormInput({key: 'eventDatetime', title: 'Event datetime', colSpan: 24, input: (<input type={'datetime-local'} className={'ant-input'} style={{ width: '100%', minHeight: '32px' }} />), rules: [{ required: true, message: 'Please enter a valid datetime.' }] })}
        </Row>,
        <Row gutter={8} key={'social-row'}>
          <Col span={24} key={'social'}>
            <Title level={5}>Social</Title>
          </Col>
          {renderFormInput({key: 'fbLink', title: 'Facebook', colSpan: 24, rules: [] })}
          {renderFormInput({key: 'igLink', title: 'Instagram', colSpan: 24, rules: [] })}
        </Row>,
      ]}
      onSave={async (data) => {
        if (event?.id) {
          data.id = event.id
        }
        if (data.eventDatetime) {
          data.eventDatetime = formatDateTime(moment(data.eventDatetime).utc(), FORMAT_SQL)
        }
        let savedEvent = await Dao.event.set(data)
        if (savedEvent !== null) {
          setEvent(savedEvent)
          message.success('Event saved')
        }
        history.replace(`/event/${savedEvent.id}`)
      }}
      onDelete={!!event?.id ? (async () => {
        let result = await Dao.event.remove(event.id)
        if (result == null) {
          message.error('Deleting event failed')
        } else {
          message.success('Event deleted')
          history.replace(`/events`)
        }
      }) : null}
    >

    </BaseForm>
  )
}