import { useEffect }    from 'react'
import Dao                       from '../utils/Dao'
import { useHistory, useParams } from 'react-router-dom'
import { useState }              from 'react'
import BaseRegistration from './common/BaseRegistration'
import { message }      from 'antd'

export default function Registration() {

  let { id } = useParams()
  let history = useHistory()

  const [categories, setCategories] = useState([])
  const [registration, setRegistration] = useState(null)

  useEffect(() => {
    async function fetchData() {
      let catResponse = await Dao.category.list()
      setCategories(catResponse.data)
      let reg = {}
      if (!isNaN(id)) {
        reg = await Dao.registration.get({ id: id })
      }
      setRegistration(reg)
    }
    fetchData()
  }, [id])



  return (<BaseRegistration
    categories={categories}
    registration={registration}
    onSave={(response) => {
      setRegistration(response)
      history.replace(`/registration/${response.id}`)
      message.success("Entry successfully saved")}
    }
  />)

}