import { Empty, PageHeader } from 'antd'

export default function Dashboard() {

  return (
    <>
      <PageHeader title={'Dashboard'} />
      <Empty description={'Dashboard is empty for now'} />
    </>
  );
}