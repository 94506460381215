import React, { useState, useEffect }                     from 'react'
import { Avatar, Button, Divider, Layout, Menu, Tooltip } from 'antd'
import { withRouter, Link, useLocation }                  from 'react-router-dom'
import {
  DashboardOutlined,
  LogoutOutlined,
}                                                         from '@ant-design/icons'
import { DEFAULT_PATHNAME }                               from '../utils/Consts'
import Dao                                                from '../utils/Dao'
import { ConfigPages }                                    from '../Config'

const { Sider } = Layout;

const sanitizeLocation = (pathname) => {
  return pathname === '/' ? DEFAULT_PATHNAME : pathname
}

function GeneralMenu({match}) {

  const location = useLocation()

  const [selectedKey, setSelectedKey] = useState(sanitizeLocation(location.pathname))

  useEffect(() => {
    setSelectedKey(sanitizeLocation(location.pathname))
    const page = ConfigPages.findPageByLocationPathname(location.pathname)
    const prefix = page ? `${page.title} - ` : ''
    document.title = `${prefix}CarWrapper`
  }, [location])

  const userElement = Dao.loggedUser ? (<>
    <div className={'menu-user-container'} >
      <Avatar size="large">
        {Dao.loggedUser.name.substr(0, 1).toUpperCase()}
      </Avatar>
      <Link to={`/user/${Dao.loggedUser.id}`} className={'menu-user-name'}>
        {Dao.loggedUser.name}
      </Link>
      <Tooltip title={'Logout'}>
        <Button icon={<LogoutOutlined />} type={'dashed'} shape={'circle'} onClick={(e) => {
          Dao.user.logout().then(response => {
            Dao.setLoggedUser(null, true)
          }).catch(response => {
            Dao.setLoggedUser(null, true)
          })
        }} />
      </Tooltip>
    </div>
    <Divider style={{ margin: 0 }} />
  </>) : <></>

  return (
    <Sider
      className="site-layout-background"
      style={{
        overflow: 'auto',
        height: '100vh',
      }}>
      <Layout>
        {userElement}
        <Menu
          mode={'inline'}
          selectedKeys={[selectedKey]}
        >
          <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
            <Link to={'/dashboard'}>Dashboard</Link>
          </Menu.Item>
          {
            ConfigPages.menuFiltered().map((item) => (
              <Menu.Item key={item.menu.path} icon={item.menu.icon}>
                <Link to={item.menu.path}>{item.title}</Link>
              </Menu.Item>
            ))
          }
        </Menu>
      </Layout>
    </Sider>
  );
}

export default withRouter(GeneralMenu);