import { Link }                          from 'react-router-dom'
import { formatDateTime, parseDateTime } from '../utils/Helper'
import { useEffect, useState }           from 'react'
import Dao                               from '../utils/Dao'
import { Button, PageHeader, Skeleton }  from 'antd'
import { SearchableTable }               from '../utils/Element'

export default function Events() {

  const columns = [
    { title: 'Title', dataIndex: 'title',
      render: (text, rowData) => <Link to={`/event/${rowData.id}`}>{text}</Link>,
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: 'Date', dataIndex: 'eventDatetime',
      render: (eventDatetime, data) => formatDateTime(parseDateTime(eventDatetime)),
      sorter: (a, b) => a.eventDatetime.localeCompare(b.eventDatetime)
    },
    {
      title: 'State', dataIndex: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state)
    },
    {
      title: 'Created', dataIndex: 'created',
      render: (created) => formatDateTime(parseDateTime(created)),
      sorter: (a, b) => a.created.localeCompare(b.created)
    },
  ];

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Dao.event.list().then(response => {
      setLoading(false)
      setEvents(response.data)
    })
  }, [])

  return (
    <>
      <PageHeader title={'Events'} extra={[
        <Link to={'/event/new'} key={'add-new'}>
          <Button type={'primary'}>
            Add new
          </Button>
        </Link>
      ]} />
      {loading && (<Skeleton key={'skeleton'} active />)}
      {!loading && (<SearchableTable
        columns={columns}
        dataSource={events} />)
      }
    </>
  )
}