import { Button, Col, Form, Input, Space, Table } from 'antd'
import Title                                      from 'antd/lib/typography/Title'
import { MinusCircleOutlined, PlusOutlined }      from '@ant-design/icons'
import { useEffect, useState }                    from 'react'
import { normalizeWithoutAccent }                 from './Helper'

export const renderFormInput = ({key, title, rules, input, colSpan = 8}) => {
  return (<Col xs={{ span: 24 }} md={{ span: colSpan }}>
    <Form.Item
      key={key}
      label={title}
      name={key}
      rules={rules ?? [{ required: true, message: `Please enter the ${title}.` }]}
    >
      {input ? input : (<Input />)}
    </Form.Item>
  </Col>)
}

export const renderFormList = ({ title, name, single, }) => {
  return (<Col xs={{ span: 24 }} md={{ span: 12 }}>
    <Title level={5}>{title}</Title>
    <Form.List name={name}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              key={field.key}
              wrapperCol={{ xs: { span: 24, offset: 0 }, }}
            >
              <Form.Item
                {...field}
                validateTrigger={['onChange', 'onBlur']}
                rules={ [{ required: true, message: `Please enter ${single} or remove empty item.`, }, ] }
                noStyle
              >
                <Input style={{ width: '80%' }} />
              </Form.Item>
              <MinusCircleOutlined
                className={'dynamic-delete-button'}
                onClick={() => remove(field.name)}
              />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '80%' }}
              className={'btn-add-form-item'}
              icon={<PlusOutlined />}
            >{`Add ${single}`}</Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  </Col>)
}

export function SearchableTable({columns, dataSource}) {

  const [query, setQuery] = useState('')
  const [items, setItems] = useState(dataSource)

  useEffect(() => {
    setItems((items) => {
      if (query === '') {
        return dataSource
      }
      return dataSource.filter(item => {
        return Object.getOwnPropertyNames(item).some(key => {
          if (['boolean'].indexOf(typeof item[key]) > -1) {
            return false;
          }
          return (!item[key] ? '' : normalizeWithoutAccent(item[key].toString())).toLowerCase().includes(query)
        })
      })
    })
  }, [query, dataSource])

  return (
    <>
      <Space key={'space'} style={{ marginBottom: '10px' }}>
        <Input.Search allowClear={true} onSearch={(query) => {
          setQuery(normalizeWithoutAccent(query.toLowerCase()))
        }} style={{ width: 250 }} />
      </Space>
      <Table
        key={'table'}
        rowKey={obj => obj.id}
        columns={columns}
        dataSource={items} />
    </>
  )

}