import BaseForm                from '../components/BaseForm'
import { useState, useEffect }   from 'react'
import { useHistory, useParams } from 'react-router'
import Dao                                   from '../utils/Dao'
import { Form, Input, InputNumber, message } from 'antd'

export default function Category() {

  let { id } = useParams()

  const history = useHistory()
  const [category, setCategory] = useState(null)

  useEffect(() => {
    async function fetchData() {
      let cat = await Dao.category.get({ id: id })
      setCategory(cat)
    }
    if (id === 'new') {
      setCategory({})
    } else {
      fetchData()
    }
  }, [id])

  return (
    <BaseForm
      title={!!category?.id ? 'Edit category' : 'Add category'}
      parentSlug={'/categories'}
      preparingForm={category == null}
      formItems={[
        <Form.Item
          key={'name'}
          label="Name"
          name="name"
          initialValue={category?.name ?? ''}
          rules={[{ required: true, message: 'Please enter the category name.' }]}
        >
          <Input />
        </Form.Item>,
        <Form.Item
          key={'description'}
          label="Description"
          name="description"
          initialValue={category?.description ?? ''}
          rules={[{ required: true, message: 'Please enter the category description.' }]}
        >
          <Input.TextArea />
        </Form.Item>,
        <Form.Item
          key={'sku'}
          label="SKU iOS"
          name="sku"
          initialValue={category?.sku ?? ''}
          rules={[{ required: true, message: 'Please enter the category sku for iOS.' }]}
        >
          <Input />
        </Form.Item>,
        <Form.Item
          key={'skuAndroid'}
          label="SKU Android"
          name="skuAndroid"
          initialValue={category?.skuAndroid ?? ''}
          rules={[{ required: true, message: 'Please enter the category sku for Android.' }]}
        >
          <Input />
        </Form.Item>,
        <Form.Item
          key={'price'}
          label="Price"
          name="price"
          initialValue={category?.price ? parseFloat(category.price) : ''}
          rules={[{ required: true, type: "number", message: 'Please enter the category price.' }]}
        >
          <InputNumber step={'0.01'}/>
        </Form.Item>,
      ]}
      onSave={async (data) => {
        if (category?.id) {
          data.id = category.id
        }
        let savedCategory = await Dao.category.set(data)
        if (savedCategory !== null) {
          setCategory(savedCategory)
          message.success('Category saved')
        }
        history.replace(`/category/${savedCategory.id}`)
      }}
      onDelete={!!category?.id ? (async () => {
        let result = await Dao.category.remove(category.id)
        if (result == null) {
          message.error('Deleting category failed')
        } else {
          message.success('Category deleted')
          history.replace(`/categories`)
        }
      }) : null}
    >

    </BaseForm>
  )
}