import './App.css'
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Redirect,
  Switch,
  Route,
  useHistory,
  useParams
}                                     from 'react-router-dom'
import { Button, Layout, Result }     from 'antd'
import GeneralMenu                    from './components/GeneralMenu'
import Dao                            from './utils/Dao'
import Login                          from './pages/Login'
import Dashboard                      from './pages/Dashboard'
import AppRegistration                from './pages/app/AppRegistration'
import AppCompany                     from './pages/app/AppCompany'
import { ConfigPages }                from './Config'
import LoadingComponent               from './components/LoadingComponent'

const { Content, } = Layout;

function App() {

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    Dao.init()
    Dao.userChangeCallbacks.push(() => {
      if (!window.location.pathname.startsWith('/login') && !window.location.pathname.startsWith('/app')) {
        window.location.pathname = ''
      }
    })
    setInitialized(true)
  }, [])

  if (initialized === false) {
    const isOpenInApp = window.location.pathname.startsWith('/app/')
    return (<Layout className={isOpenInApp ? 'dark-layout' : ''}>
      <Content>
        <LoadingComponent />
      </Content>
    </Layout>)
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path={'/app/registration/:token/:sku'} exact render={(props) => {
          return (<TokenRequiredComponent component={AppRegistration} />)
        }} />
        {/* AppCompany for creating is public and for editing is TokenRequired */}
        <Route path={'/app/company'} exact component={AppCompany} />
        <Route path={'/app/company/public/:parts?'} exact component={AppCompany} />
        <Route path={'/app/company/:token/:parts?'} exact render={(props) => {
          return (<TokenRequiredComponent component={AppCompany} />)
        }} />
        <Route>
          <Layout>
            <GeneralMenu />
            <Layout style={{  }}>
              <Content style={{ padding: '0 1.5rem' }}>
                <Switch>
                  {ConfigPages.pages.map((page, index) => (
                    <PrivateRoute key={`page-${index}`} path={page.path} component={page.component} />
                  ))}
                  <PrivateRoute component={Dashboard} />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function TokenRequiredComponent({ component: Component, ...rest }) {

  let { token } = useParams()
  const [user, setUser] = useState(null); // user can be 'null' => initial(authenticating), false => forbidden, object => allowed

  useEffect(() => {
    async function fetchData() {
      try {
        let userResponse = await Dao.user.validateHash(token)
        Dao.setLoggedUser(userResponse)
        setUser(userResponse)
      } catch (e) {
        setUser(false)
      }
    }
    fetchData()
  }, [token])

  return user === null
    ? (<LoadingComponent />)
    : (user === false ? (<NoAccessComponent />) : (<Component {...rest} />))
}

function PrivateRoute({component: Component, ...rest}) {
  let authed = Dao.isAuthenticated
  return (
    <Route
      {...rest}
      render={(props) => {
        return authed === true
          ? <SpecifiedAccessRoute component={Component} {...props} />
          : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
      }}
    />
  )
}

function SpecifiedAccessRoute( { component: Component, match, ...rest } ) {
  const configItem = ConfigPages.findPage(match.path)
  const hasAccess = !configItem || ConfigPages.hasAccess(Dao.loggedUser.access, configItem.requiredAccess)

  return (hasAccess ? (<Component {...rest} />) : (<NoAccessComponent />))
}

function NoAccessComponent() {

  const history = useHistory()

  return (
    <Result
      status={'403'}
      subTitle={'Sorry, you are not authorized to access this page.'}
      extra={<Button type={'primary'} onClick={(e) => {
        history.goBack()
      }}>Go back</Button>}
    />
  )
}

export default App;
