import { Button, PageHeader, Skeleton, Tag } from 'antd'
import Dao                                   from '../utils/Dao'
import { useEffect, useState }               from 'react'
import { Link }                              from 'react-router-dom'
import { SearchableTable }                   from '../utils/Element'

export default function Categories() {

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', width: 200,
      render: (text, rowData) => <Link to={`/category/${rowData.id}`}>{text}</Link>,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Description', dataIndex: 'description', key: 'description',
    },
    {
      title: 'SKU iOS', dataIndex: 'sku', key: 'sku',
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      render: (text, data) => {
        const packageTag = data.isPackage && (<Tag color={'success'}>{'package'}</Tag>)
        return (<>
          <Tag>{text}</Tag>
          {packageTag}
        </>)
      },
    },
    {
      title: 'SKU Android', dataIndex: 'skuAndroid', key: 'skuAndroid',
      sorter: (a, b) => a.skuAndroid.localeCompare(b.skuAndroid),
      render: (text, data) => {
        const packageTag = data.isPackage && (<Tag color={'success'}>{'package'}</Tag>)
        return (<>
          <Tag>{text}</Tag>
          {packageTag}
        </>)
      },
    },
    {
      title: 'Price', dataIndex: 'price', key: 'price', width: 100,
      render: price => `${parseFloat(price).toFixed(2)} €`,
      sorter: (a, b) => a.price - b.price
    },
  ];

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Dao.category.list().then(response => {
      setLoading(false)
      setCategories(response.data)
    })
  }, [])

  return (
    <>
      <PageHeader title={'Categories'} extra={[
        <Link key={'add-new'} to={'/category/new'}>
          <Button
                  type={'primary'}>
            Add new
          </Button>
        </Link>,
      ]} />
      {loading && (<Skeleton active />)}
      {!loading && (
        <SearchableTable
          columns={columns}
          dataSource={categories} />)
      }
    </>
  )
}