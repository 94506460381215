import { Link }                          from 'react-router-dom'
import { Button, PageHeader, Skeleton }  from 'antd'
import Dao                               from '../utils/Dao'
import { useState, useEffect }           from 'react'
import { formatDateTime, parseDateTime } from '../utils/Helper'
import { SearchableTable }               from '../utils/Element'

export default function Companies() {

  const originFilters = [
    { text: 'Admin', value: 'admin' },
    { text: 'Application', value: 'app' },
  ]

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name',
      render: (text, rowData) => <Link to={`/company/${rowData.id}`}>{text}</Link>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.name.includes(value)
    },
    {
      title: 'Address', dataIndex: 'street', key: 'street',
      render: (street, data) => [data.street, data.streetNo, data.zipCode, data.city, data.state, data.country].filter(str => str !== null && str !== '').join(', '),
      sorter: (a, b) => a.street.localeCompare(b.street)
    },
    {
      title: 'Origin', dataIndex: 'originApp', render: (originApp, data) => {
        switch (originApp) {
          case 'admin':
            return 'Admin'
          case 'app':
            return 'Application'
          default :
            return originApp
        }
      },
      filters: originFilters,
      defaultFilteredValue: ['app'],
      onFilter: (value, record) => record.originApp === value,
    },
    {
      title: 'Created', dataIndex: 'created', key: 'created',
      render: (created) => formatDateTime(parseDateTime(created)),
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
  ];

  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Dao.company.list().then(response => {
      setLoading(false)
      setCompanies(response.data)
    })
  }, [])

  return (
    <>
      <PageHeader title={'Companies'} extra={[
        <Link to={'/company/new'} key={'add-new'}>
          <Button type={'primary'}>
            Add new
          </Button>
        </Link>
      ]} />
      {loading && (<Skeleton key={'skeleton'} active />)}
      {!loading && (<SearchableTable
        columns={columns}
        dataSource={companies} />)
      }
    </>
  )
}