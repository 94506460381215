import { PageHeader, Skeleton, Tag }                from 'antd'
import Dao                                          from '../utils/Dao'
import { useEffect, useState }                      from 'react'
import { Link }                                     from 'react-router-dom'
import { formatDateTime, hasAccess, parseDateTime } from '../utils/Helper'
import { UserAccess }                               from '../utils/Consts'
import { SearchableTable }                          from '../utils/Element'

export default function Subscriptions() {

  const columns = [
    { title: 'Created', dataIndex: 'created', key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, rowData) => formatDateTime(parseDateTime(text)),
    },
    {
      title: 'SKU', dataIndex: 'sku', key: 'sku',
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      render: (text) => (<Tag>{text}</Tag>)
    },
    {
      title: 'User', dataIndex: 'userId', key: 'userId',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      render: (userId, rowData) => !userId ? '' : hasAccess(Dao.loggedUser, UserAccess.USERS) ? (<Link to={`/user/${rowData.userId}`}>{rowData.userName}</Link>) : rowData.userName,
    },
    {
      title: 'Category', dataIndex: 'category', key: 'categoryId',
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      render: (categoryId, rowData) => !categoryId ? '' : hasAccess(Dao.loggedUser, UserAccess.ADMIN) ? (<Link to={`/category/${rowData.categoryId}`}>{rowData.categoryName}</Link>) : rowData.categoryName,
    },
  ];

  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Dao.subscription.list({ userId: null }).then(response => {
      setLoading(false)
      setSubscriptions(response.data)
    })
  }, [])

  return (
    <>
      <PageHeader title={'Subscriptions'} />
      {loading && (<Skeleton active />)}
      {!loading && (<SearchableTable
        rowKey={subs => subs.id}
        columns={columns}
        dataSource={subscriptions} />)
      }
    </>
  )
}