import Dao                     from '../../utils/Dao'
import { Layout, message, }    from 'antd'
import { Content }             from 'antd/lib/layout/layout'
import { useState, useEffect } from 'react'
import BaseCompany             from '../common/BaseCompany'
import './AppDark.css'
import { useParams }           from 'react-router-dom'

export default function AppCompany() {

  const [company, setCompany] = useState(null)
  const params = useParams()
  const parts = (params?.parts ?? '').split(',')

  useEffect(() => {
    async function fetchData() {
      if (!Dao.loggedUser) {
        setCompany({})
      } else {
        Dao.company.get()
          .then((comp) => setCompany(comp))
          .catch((e) => setCompany({}))
      }
    }
    fetchData()
  }, [])

  return (
    <Layout className={'dark-layout'}>
      <Content style={{ padding: '0 1.5rem' }}>
        <BaseCompany
          company={company}
          overrideFormProps={{
            saveButtonText: company?.id ? 'Save' : 'Register'
          }}
          allowedParts={parts ?? []}
          beforeSave={async () => {
            if (!!Dao.loggedUser) {
              return Promise.resolve(true)
            }
            try {
              let user = await Dao.user.appLogin(navigator.userAgent)
              Dao.setLoggedUser(user, false)
              return Promise.resolve(true)
            } catch (e) {
              console.log('Error on user/appLogin', e)
              return Promise.resolve(false)
            }
          }}
          onSave={(response) => {
            setCompany(response)
            message.success("Company successfully saved").then(() => {
              window.location.pathname = `app/company/token/${Dao.loggedUser.accessToken}`
            })
          }} />
      </Content>
    </Layout>
  )
}