export const SESSION_KEY_USER = 'user'

export const FRONTEND_HOSTNAME_DEV = 'app-admin.dev.carwrapper.com';
export const API_BASE_URL_DEV = 'https://api.dev.carwrapper.com/';
export const API_BASE_URL_LIVE = 'https://api.carwrapper.com/';
export const MEDIA_UPLOAD_ENDPOINT = 'media';
export const DEFAULT_PATHNAME = '/dashboard';

export const FORMAT_SQL = 'YYYY-MM-DD HH:mm:ss'
export const FORMAT_HTML5_DATE = "YYYY-MM-DD"
export const FORMAT_HTML5_DATETIME = "YYYY-MM-DDTHH:mm"
export const FORMAT_USER = 'DD.MM.YYYY HH:mm'
export const PATTERN_URL = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/

export const API_CODES = {
  ERROR_NO_DEVICE: 1,
  ERROR_CANT_SAVE: 2,
  ERROR_CANT_DELETE: 3,
  ERROR_NOT_FOUND: 4,
  ERROR_INVALID_STATE: 5,
  ERROR_INVALID_PARAM: 6,
  ERROR_NO_ACCESS: 7,
  ERROR_SERVER_ERROR: 100,
}

export const UserAccess = {
  ADMIN: 0,
  USERS: 1,
  REGISTRATIONS: 2,
  COMPANIES: 3,
  SUBSCRIPTIONS: 4,
  EVENTS: 5,
}

export const isDev = () => window.location.hostname === 'localhost' || window.location.hostname === FRONTEND_HOSTNAME_DEV
export const baseUrl = () => isDev() ? API_BASE_URL_DEV : API_BASE_URL_LIVE