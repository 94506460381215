import User             from './pages/User'
import Users            from './pages/Users'
import Company          from './pages/Company'
import Companies        from './pages/Companies'
import Category         from './pages/Category'
import Categories       from './pages/Categories'
import Registrations  from './pages/Registrations'
import Subscriptions  from './pages/Subscriptions'
import { UserAccess }     from './utils/Consts'
import {
  HomeOutlined,
  PartitionOutlined,
  TeamOutlined,
  HeatMapOutlined,
  EuroOutlined,
  CalendarOutlined
}                         from '@ant-design/icons'
import Events             from './pages/Events'
import Event              from './pages/Event'
import RegistrationDetail from './pages/RegistrationDetail'
import Dao                from './utils/Dao'
import Registration       from './pages/Registration'

const pages = [
  { path: '/category/:id', title: 'Category', regex: /^\/category\/[0-9]+$/, component: Category, requiredAccess: [UserAccess.ADMIN] },
  { path: '/company/:id', title: 'Company', regex: /^\/company\/[0-9]+$/, component: Company, requiredAccess: [UserAccess.COMPANIES] },
  { path: '/registration/view/:id', title: 'Entry detail', regex: /^\/registration\/view\/[0-9]+$/, component: RegistrationDetail, requiredAccess: [UserAccess.REGISTRATIONS] },
  { path: '/registration/:id', title: 'Entry', regex: /^\/registration\/[0-9]+$/, component: Registration, requiredAccess: [UserAccess.REGISTRATIONS] },
  { path: '/user/:id', title: 'User', regex: /^\/user\/[0-9]+$/, component: User, requiredAccess: [UserAccess.USERS] },
  { path: '/events', title: 'Events', regex: /^\/events$/, component: Events, requiredAccess: [UserAccess.EVENTS],
    menu: { path: '/events', icon: (<CalendarOutlined />) } },
  { path: '/event/:id', title: 'Events', regex: /^\/event\/[0-9]+$/, component: Event, requiredAccess: [UserAccess.EVENTS], },
  { path: '/categories', title: 'Categories', regex: /^\/categories$/, component: Categories, requiredAccess: [UserAccess.ADMIN],
      menu: { path: '/categories', icon: (<PartitionOutlined />) } },
  { path: '/companies', title: 'Companies', regex: /^\/companies$/, component: Companies, requiredAccess: [UserAccess.COMPANIES],
    menu: { path: '/companies',  icon: (<HomeOutlined />) } },
  { path: '/registrations', title: 'Entries', regex: /^\/registrations$/, component: Registrations, requiredAccess: [UserAccess.REGISTRATIONS],
    menu: { path: '/registrations', icon: (<HeatMapOutlined />) } },
  { path: '/users', title: 'Users', regex: /^\/users$/, component: Users, requiredAccess: [UserAccess.USERS],
    menu: { path: '/users', icon: (<TeamOutlined />) } },
  { path: '/subscriptions', title: 'Subscriptions', regex: /^\/subscriptions$/, component: Subscriptions, requiredAccess: [UserAccess.SUBSCRIPTIONS],
    menu: { path: '/subscriptions', icon: (<EuroOutlined />) } },
]

const hasAccessImpl = (userAccess, requiredAccess) => requiredAccess.length === 0 || requiredAccess.some((confIndex) => userAccess[confIndex] === 1)

export const ConfigPages = {
  pages: pages,
  menuFiltered: () => pages.filter(it => !!it.menu).filter(it => hasAccessImpl(Dao.loggedUser?.access ?? [], it.requiredAccess)),
  findPage: (routePath) => pages.find(it => it.path === routePath),
  findPageByLocationPathname: (location) => pages.find(it => it.regex.test(location)),
  hasAccess: (userAccess, requiredAccess) => hasAccessImpl
}

export const Config = {
  maxRegistrationPhotosCount: 3,
  maxRegistrationVideosCount: 1,
}