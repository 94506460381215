import Dao                         from '../../utils/Dao'
import { Layout, message, Result } from 'antd'
import { useParams }               from 'react-router'
import { useState, useEffect }     from 'react'
import { Content }                 from 'antd/lib/layout/layout'
import BaseRegistration            from '../common/BaseRegistration'
import { API_CODES }               from '../../utils/Consts'
import LoadingComponent            from '../../components/LoadingComponent'
import './AppDark.css'

export default function AppRegistration() {

  let { sku } = useParams()

  const [registration, setRegistration] = useState(null)
  const [category, setCategory] = useState(null)
  const [company, setCompany] = useState(null)

  useEffect(() => {
    async function fetchData() {
      let categoryResponse
      let companyResponse
      try {
        categoryResponse = await Dao.category.get({ sku: sku })
        setCategory(categoryResponse)
        companyResponse = await Dao.company.get()
        setCompany(companyResponse)
      } catch (e) {
        setCategory(false)
        setCompany(false)
        return
      }
      try {
        let registration = await Dao.registration.get({ companyId: companyResponse.id, categoryId: categoryResponse.id })
        setRegistration(registration)
      } catch (e) {
        if (e.errorCode === API_CODES.ERROR_NOT_FOUND) {
          setRegistration({})
        } else {
          setRegistration(false)
        }
      }
    }
    fetchData()
  }, [sku])

  if (category === false || company === false || registration === false) {
    return (<Result
      status={'403'}
      subTitle={'Sorry, you are not authorized to access this page.'}
    />)
  }

  if (category === null || company === null || registration === null) {
    return (<LoadingComponent />)
  }

  return (
    <Layout className={'dark-layout'}>
      <Content style={{ padding: '0 1.5rem' }}>
        <BaseRegistration
          categories={[category]}
          registration={registration}
          company={company}
          overrideFormProps={{ parentSlug: null }}
          onSave={(response) => {
            setRegistration(response)
            message.success("Entry successfully saved")
          }}
        />
      </Content>
    </Layout>
  )
}