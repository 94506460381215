import * as Consts from './Consts'
import { baseUrl } from './Consts'

/**
 * Important note: localStorage can be not available (e.g. on Android device)
 */
const Dao = {

  loggedUser: null,
  isAuthenticated: false,
  userChangeCallbacks: [],
  init() {
    let userJson = localStorage?.getItem(Consts.SESSION_KEY_USER) ?? null;
    let loggedUser = userJson === null ? null : JSON.parse(userJson)
    this.loggedUser = loggedUser
    this.isAuthenticated = loggedUser !== null
  },
  category: {
    get: (req) => new Api(`category/`).get(req),
    set: (data) => new Api('category/').post(data),
    list: () => new Api(`category/list`).get(),
    remove: (id) => new Api('category/').delete({ id: id })
  },
  user: {
    get: (id) => new Api(`user/`).get({ id: id }),
    set: (data) => new Api('user/').post(data),
    list: () => new Api(`user/`).get(),
    remove: (id) => new Api('user/').delete({ id: id }),
    login: (email, password, userAgent) => new Api('user/login').post({ email: email, password: password, userAgent: userAgent }),
    appLogin: (userAgent) => new Api('user/appLogin').post({ userAgent: userAgent }),
    logout: () => new Api('user/logout').get(),
    validateHash: (token) => new Api('user/validateHash').get({ token: token }),
  },
  company: {
    get: (id) => {
      let data = id ? { id: id } : {}
      return new Api(`company/`).get(data)
    },
    set: (data) => new Api('company/').post(data),
    list: () => new Api(`company/list`).get(),
    remove: (id) => new Api('company/').delete({ id: id })
  },
  registration: {
    get: (req) => new Api(`registration/`).get(req),
    set: (data) => new Api('registration/').post(data),
    list: ({categoryId = null, companyId = null, fields = []}) => {
      let params = { fields: fields }
      if (!!categoryId) {
        params.categoryId = categoryId
      }
      if (!!companyId) {
        params.companyId = companyId
      }
      return new Api(`registration/list`).get(params)
    },
    remove: (id) => new Api('registration/').delete({ id: id })
  },
  media: {
    remove: (id) => new Api('media').delete({ id: id })
  },
  subscription: {
    list: ({ userId }) => {
      let params = {}
      if (userId) {
        params.userId = userId
      }
      return new Api(`subscription/list`).get(params)
    },
  },
  event: {
    get: (id) => {
      let data = id ? { id: id } : {}
      return new Api(`event/`).get(data)
    },
    set: (data) => new Api('event/').post(data),
    list: () => new Api(`event/list`).get(),
    remove: (id) => new Api('event/').delete({ id: id })
  },
  setLoggedUser(loggedUserData = null, triggerCallbacks = true) {
    this.loggedUser = loggedUserData
    this.isAuthenticated = loggedUserData !== null
    if (loggedUserData !== null) {
      localStorage?.setItem(Consts.SESSION_KEY_USER, JSON.stringify(loggedUserData))
    } else {
      localStorage?.removeItem(Consts.SESSION_KEY_USER)
    }
    if (triggerCallbacks === true) {
      for(let cb of this.userChangeCallbacks) {
        cb()
      }
    }
  }
}

class Api {

  constructor(endPoint) {
    this.endPoint = endPoint
  }

  async get(params) {
    let query = this.serialize(params, '')
    let url = baseUrl() + `${this.endPoint}` + (query === '' ? '' : `?${query}`)

    let text = await this.send(url, 'GET', null)
    return this.parseResponse(text)
  }

  async delete(params) {
    let query = this.serialize(params, '')
    let url = baseUrl() + `${this.endPoint}` + (query === '' ? '' : `?${query}`)

    let text = await this.send(url, 'DELETE', null)
    return this.parseResponse(text)
  }

  async post(data) {
    let url = baseUrl() + this.endPoint
    let text = await this.send(url, 'POST', data === null ? null : JSON.stringify(data))
    return this.parseResponse(text)
  }

  async send(url, method, body) {
    let headers = {
      'Content-Type': 'application/json',
    }
    if (!!Dao.loggedUser?.accessToken) {
      headers['X-CarWrapper-AccessToken'] = Dao.loggedUser?.accessToken
    }
    let response = await fetch(url, {
      headers: headers,
      method: method,
      body: body
    });
    if (response.status === 500) {
      console.log('HTTP error 500. Server error, could not load data.')
    } else if (response.status === 401) {
      // not logged user
      Dao.setLoggedUser(null, true)
    }
    if (Math.floor(response.status / 200) !== 1) {
      return null
    }
    return response.text()
  }

  parseResponse(responseText) {
    let response = {}
    try {
       response = JSON.parse(responseText)
    } catch (e) {
      return Promise.reject('Can\'t parse response')
    }
    if (response.errorCode) {
      if (response.errorCode === Consts.API_CODES.ERROR_NO_DEVICE) {
        Dao.setLoggedUser(null, true)
      }
      return Promise.reject(response)
    }
    return Promise.resolve(response)
  }

  serialize(obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }
}

export default Dao;
