import moment                      from 'moment'
import { FORMAT_USER, FORMAT_SQL } from './Consts'

export const hasAccess = (user, accessIndex) => {
  let sanitized = user?.access ?? []
  return sanitized.length > accessIndex ? sanitized[accessIndex] === 1 : false;
}

export const formatDateTime = (momentDateTime, format = FORMAT_USER) => {
  return momentDateTime.format(format);
}

export const formatToUtc = (momentDateTime) => {
  return momentDateTime.utc().format(FORMAT_SQL)
}

export const parseDateTime = (utcDateTime) => {
  return moment.utc(utcDateTime).local()
}

export const normalizeWithoutAccent = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}